import { Injectable } from '@angular/core';
import {
	Router,
	CanActivate,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
} from '@angular/router';
import { LocalStorageService } from '@core/services/local-storage/local-storage.service';
import { AuthenticationService } from 'app/pages/auth/services/authentication.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
	/**
	 *
	 * @param {Router} _router
	 * @param {AuthenticationService} _authenticationService
	 */
	constructor(
		private _router: Router,
		private _authenticationService: AuthenticationService,
		private localStorage: LocalStorageService
	) {}

	// canActivate(next: ActivatedRouteSnapshot): boolean {
	// 	const res = this._authenticationService.checkToken();
	// 	const userModulePermissions = JSON.parse(this.localStorage.getItem('module-permissions'));
	// 	const allRoles = ['admin', 'admin_user'];
	// 	if (!res) {
	// 		this._router.navigate(['/login']);
	// 		return false;
	// 	}
	// 	const role = JSON.parse(localStorage.getItem('APP-tokens'));
	// 	if (allRoles.indexOf(role.user_details.role) == -1) {
	// 		localStorage.removeItem('APP-tokens');
	// 		this._router.navigate(['/login']);
	// 		return false;
	// 	}

	// 	if (
	// 		next.data && userModulePermissions &&
	// 		(userModulePermissions.indexOf(next.data.title) == -1 ) && next.data.title != 'Dashboard' && next.data.title != 'Profile' && next.data.title != 'JitBit' &&next.data.title != 'Azure' && next.data.title != 'Watch'
	// 	) {
	// 		debugger
	// 		this._router.navigate(['/dashboard']);
	// 		return false;
	// 	}

	// 	return true;
	// }
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		const currentUser = this._authenticationService.currentUserValue;
		let token = this._authenticationService.isSessionActive()
		// console.log(token,this.authenticationService.token)
		if (token) {
		  // if(currentUser.userInfo.isfirstloggin == true && state.url != "/profile/change-password") {
		  // 	this.router.navigate(['profile/change-password'])
		  // 	return false
		  // }
		  console.log(token)
		  return true;
		} else {
		  this._router.navigate(['/login']);
		  return false;
		}
		// const currentUser = this.authenticationService.currentUserValue;
		
		// if (currentUser?.authToken) {
		//   if(currentUser.userInfo.isfirstloggin == true && state.url != "/profile/change-password") {
		//   	this.router.navigate(['profile/change-password'])
		//   	return false
		//   }
		//   return true;
		// }
	
		// this.router.navigate(['/register'], {
		//   queryParams: { returnUrl: state.url },
		// });
		// return false;
	  }
}
