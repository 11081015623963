<!-- Vertical Menu -->
<ng-container *ngIf="layout === 'vertical' ">
  <ng-container *ngFor="let item of menu">
    <!-- section -->
   

    <!-- sub-menu item/collapsible of sections  -->
    <ng-container *ngIf="item.type == 'section'">
      <ng-container *ngFor="let item of item.children">
        <!-- item | if openInNewTab set routerLink & routerLinkActive blank -->
        <li
          core-menu-vertical-item
  
          [item]="item"
          [ngClass]="{ disabled: item.disabled === true }"
          [routerLinkActive]="!item.openInNewTab ? 'active' : ''"
          [routerLinkActiveOptions]="{ exact: item.exactMatch || false }"
        >
          <!-- Fix: Required routerLink to identify the active -->
          <span [routerLink]="item.openInNewTab ? [] : [item.url]" class="d-none"></span>
        </li>
        <!-- collapsible -->
        <li
          core-menu-vertical-collapsible

          [item]="item"
          class="nav-item has-sub"
        ></li>
      </ng-container>
    </ng-container>

    <!-- item -->
    <li
      core-menu-vertical-item
      *ngIf="
        item.type == 'item' && (
        item.id == 'dashboard' )
      "
      [item]="item"
      [ngClass]="{ disabled: item.disabled === true }"
      [routerLinkActive]="!item.openInNewTab ? 'active' : ''"
      [routerLinkActiveOptions]="{ exact: item.exactMatch || false }"
    >
      <span [routerLink]="item.openInNewTab ? [] : [item.url]" class="d-none"></span>
    </li>
    <!-- collapsible -->
    <li
      core-menu-vertical-collapsible
      *ngIf="
        item.type == 'collapsible' &&
        ((userModulePermissions ? userModulePermissions.includes(item.title) : false) || 
        item.id == 'dashboard' || (item.id == 'jitbit' && currentUser.role != 'admin'))
      "
      [item]="item"
      class="nav-item has-sub"
    ></li>
  </ng-container>
</ng-container>
<!--/ Vertical Menu -->

<!-- Horizontal Menu -->
<ng-container *ngIf="layout === 'horizontal' ">
  <ng-container *ngFor="let item of menu">
    <!-- section -->
    <li
      core-menu-horizontal-collapsible
      *ngIf="
        item.type == 'section' && ((userModulePermissions ? userModulePermissions.includes(item.title) : false) || 
        item.id == 'dashboard' || (item.id == 'jitbit' && currentUser.role != 'admin'))
      "
      [item]="item"
      class="dropdown nav-item"
    ></li>

    <!-- item | if openInNewTab set routerLink & routerLinkActive blank -->
    <li
      core-menu-horizontal-item
   
      [item]="item"
      [ngClass]="{ disabled: item.disabled === true }"
      [routerLinkActive]="!item.openInNewTab ? 'active' : ''"
      [routerLinkActiveOptions]="{ exact: item.exactMatch || false }"
    >
      <span [routerLink]="item.openInNewTab ? [] : [item.url]" class="d-none"></span>
    </li>

    <!-- collapsible -->
    <li
      core-menu-horizontal-collapsible
      *ngIf="
        item.type == 'collapsible' &&
        ((userModulePermissions ? userModulePermissions.includes(item.title) : false) || 
        item.id == 'dashboard' || (item.id == 'jitbit' && currentUser.role != 'admin'))
      "
      [item]="item"
      class="dropdown dropdown-submenu"
    ></li>
  </ng-container>
</ng-container>
<!--/ Horizontal Menu -->
