<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">
  <!-- Navbar brand -->
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item">
      <a class="navbar-brand" [routerLink]="['/dashboard']">
        <span class="brand-logo">
          <img
            src="{{ coreConfig.app.appLogoImage }}"
            alt="brand-logo"
            height="28"
            style="width: auto; max-width: none;height:2rem;"
          />
        </span>
        <!-- <h2 class="brand-text mb-0">{{ coreConfig.app.appName }}</h2> -->
      </a>
    </li>
  </ul>
  <!--/ Navbar brand -->
</div>

<div class="navbar-container d-flex content">
  <div class="bookmark-wrapper d-flex align-items-center">
    <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
    <ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">
      <li class="nav-item">
        <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
          <span [data-feather]="'menu'" [class]="'ficon'"></span>
        </a>
      </li>
    </ul>
    <!--/ Menu Toggler -->

    <!-- Bookmark -->
    <!-- <app-navbar-bookmark></app-navbar-bookmark> -->
    <!--/ Bookmark -->
    <!-- Quick access -->
    <!-- Bookmarked Icons -->
    <ul class="nav navbar-nav bookmark-icons">

      <!-- commented based on BNU-576 comments -->

      <!-- <ng-container>
        <li class="nav-item d-none d-lg-block">
          <a class="nav-link" placement="bottom" ngbTooltip="Email">
            <span data-feather="mail" [class]="'ficon'"></span>
          </a>
        </li>
      </ng-container>

      <ng-container>
        <li class="nav-item d-none d-lg-block">
          <a class="nav-link" placement="bottom" ngbTooltip="Chat">
            <span data-feather="message-square" [class]="'ficon'"></span>
          </a>
        </li>
      </ng-container>

      <ng-container>
        <li class="nav-item d-none d-lg-block">
          <a class="nav-link" placement="bottom" ngbTooltip="Todo">
            <span data-feather="check-square" [class]="'ficon'"></span>
          </a>
        </li>
      </ng-container>

      <ng-container>
        <li class="nav-item d-none d-lg-block">
          <a class="nav-link" placement="bottom" ngbTooltip="Calendar">
            <span data-feather="calendar" [class]="'ficon'"></span>
          </a>
        </li>
      </ng-container> -->

      <!-- End -->

      <ng-container>
        <!-- <li ngbDropdown class="nav-item dropdown-user"  [placement]="'bottom-right'">
          <a
            class="nav-link dropdown-toggle dropdown-user-link"
            id="dropdown-user"
            ngbDropdownToggle
            id="navbarUserDropdown"
            aria-haspopup="true"
            aria-expanded="false"
            [ngClass]="{
              'csv-danger': csv.task_status != 'completed',
              'csv-success': csv.task_status =='completed'
            }"
          >
            <ng-container>
              <span data-feather="download" [class]="'ficon'"></span>
            </ng-container>
          </a>
          <div
            ngbDropdownMenu
            aria-labelledby="navbarUserDropdown"
            class="dropdown-menu  dl-menu"
          >
           
            <div>
              <div ngbDropdownItem
            class="spinner-border text-secondary"
            role="status"
            style="margin-right: 12px; margin-left: 12px"
            *ngIf="generateLoading"
          >
            <span class="sr-only">Loading...</span>
          </div>
          <a
            *ngIf="!generateLoading"
            class="nav-link dl-menu-link"
            placement="bottom"
            ngbTooltip="Regenerate"
            (click)="reGenerate()"
          >
            <span data-feather="refresh-ccw" [class]="'ficon'"></span>
            <span class="text-secondary  dl-menu-txt">
              Regenerate
            </span>
          </a>
            </div>

            <div
            class="spinner-border text-secondary"
            role="status"
            style="margin-right: 12px; margin-left: 12px"
            *ngIf="exportLoading"
          >
            <span class="sr-only">Loading...</span>
          </div>

          
          <a
            *ngIf="!exportLoading"
            class="nav-link  dl-menu-link"
            placement="bottom"
            [ngbTooltip]="csv.task_status != 'completed' ? 'Plaease Wait' : 'Export CSV'"
            (click)="downloadLink()"
            [ngClass]="{
              'cs-d': csv.task_status != 'completed',
              'csv-s': csv.task_status =='completed'
            }"
            [ngClass]="{
              'csv-danger': csv.task_status != 'completed',
              'csv-success': csv.task_status =='completed'
            }"
          >
            <span data-feather="download" [class]="'ficon'"></span>
            <span class="text-secondary  dl-menu-txt">
              Download
            </span>
            
          </a>


      
          </div>
        </li> -->

      </ng-container>

      <!-- <ng-container>
        <li class="nav-item d-none d-lg-block">
          <div
            class="spinner-border text-secondary"
            role="status"
            style="margin-right: 12px; margin-left: 12px"
            *ngIf="generateLoading"
          >
            <span class="sr-only">Loading...</span>
          </div>
          <a
            *ngIf="!generateLoading"
            class="nav-link"
            placement="bottom"
            ngbTooltip="Regenerate"
            (click)="reGenerate()"
          >
            <span data-feather="refresh-ccw" [class]="'ficon'"></span>
          </a>
          
        </li>
      </ng-container>

      <ng-container>
        <li class="nav-item d-none d-lg-block" >
          <div
            class="spinner-border text-secondary"
            role="status"
            style="margin-right: 12px; margin-left: 12px"
            *ngIf="exportLoading"
          >
            <span class="sr-only">Loading...</span>
          </div>
          <a
            *ngIf="!exportLoading"
            class="nav-link"
            placement="bottom"
            [ngbTooltip]="csv.task_status != 'completed' ? 'Plaease Wait' : 'Export CSV'"
            (click)="downloadLink()"
            [ngClass]="{
              'csv-danger': csv.task_status != 'completed',
              'csv-success': csv.task_status =='completed'
            }"
          >
            <span data-feather="download" [class]="'ficon'"></span>
          </a>
          
        </li>
      </ng-container> -->
      

      <!-- <ng-container>
          <app-navbar-watchlist></app-navbar-watchlist>
      </ng-container> -->

      <!-- <ng-container>
        <li class="nav-item d-none d-lg-block">
          <a
            class="nav-link"
            routerLink="/jitbit"
            placement="bottom"
            ngbTooltip="Help Desk"
          >
            <span data-feather="help-circle" [class]="'ficon'"></span>
          </a>
        </li>
      </ng-container> -->

     
    </ul>
    <!-- quick access -->
  </div>
  <ul class="nav navbar-nav align-items-center ml-auto">
    <!-- Language selection -->
    <li ngbDropdown class="nav-item dropdown dropdown-language">
      <a class="nav-link dropdown-toggle" id="dropdown-flag" ngbDropdownToggle>
        <i class="flag-icon flag-icon-{{ getCurrentLanguage.flag }}"></i
        ><span class="selected-language notranslate">{{
          getCurrentLanguage.title
        }}</span></a
      >
      <div
        ngbDropdownMenu
        class="translation-links"
        aria-labelledby="dropdown-flag"
      >
        <a
          *ngFor="let lang of languageOptions"
          ngbDropdownItem
          class="notranslate"
          (click)="setLanguage(lang.value)"
        >
          <i class="flag-icon flag-icon-{{ lang.flag }}"></i>
          {{ lang.title }}
        </a>
        <hr />
        <input
          type="text"
          class="language-search form-control"
          (keyup)="onSearchLanguage()"
          [(ngModel)]="languageSearch"
          placeholder="Search your language.."
        />

        <div *ngIf="languageSearch != '' && searchLanguageResults?.length > 0">
          <a
            *ngFor="let lang of searchLanguageResults"
            ngbDropdownItem
            class="notranslate"
            (click)="setLanguage(lang.flag)"
          >
            <i data-feather="globe"></i>
            {{ lang.title }}
          </a>
        </div>

        <div
          *ngIf="languageSearch != '' && searchLanguageResults?.length <= 0"
          class="not-found-text"
        >
          <small>Not found</small>
        </div>
      </div>
    </li>
    <!--/ Language selection -->

    <!-- Toggle skin -->
    <li class="nav-item d-none d-lg-block">
      <a class="nav-link nav-link-style btn" (click)="toggleDarkSkin()">
        <span
          [ngClass]="currentSkin === 'dark' ? 'icon-sun' : 'icon-moon'"
          class="ficon font-medium-5 feather"
        ></span>
      </a>
    </li>
    <!--/ Toggle skin -->

    <!-- Search -->
    <!-- <app-navbar-search></app-navbar-search> -->
    <!--/ Search -->

    <!-- Notification -->
    <!-- <app-navbar-notification></app-navbar-notification> -->
    <!--/ Notification -->

    <!-- User Dropdown -->
    <li ngbDropdown class="nav-item dropdown-user">
      <a
        class="nav-link dropdown-toggle dropdown-user-link"
        id="dropdown-user"
        ngbDropdownToggle
        id="navbarUserDropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <ng-container>
          <div class="user-nav d-sm-flex d-none">
            <span class="user-name font-weight-bolder"
              >{{ currentUser?.first_name }} {{ currentUser?.last_name }} </span
            ><span class="user-status">{{ currentUser?.role }}</span>
          </div>
          <span class="avatar">
            <img
              class="round"
              [src]="
              profilePic
                  ? profilePic
                  : '../../../../../assets/images/avatars/13defaultAvathar.png'
              "
              alt="avatar"
              height="40"
              width="40" />
              <span class="avatar-status-online"></span
          ></span>
        </ng-container>
      </a>
      <div
        ngbDropdownMenu
        aria-labelledby="navbarUserDropdown"
        class="dropdown-menu dropdown-menu-right"
      >
        <a ngbDropdownItem [routerLink]="['/profile']"
          ><span [data-feather]="'user'" [class]="'mr-50'"></span> Profile</a
        >
        <a ngbDropdownItem (click)="logout()"
          ><span [data-feather]="'power'" [class]="'mr-50'"></span> Logout</a
        >
      </div>
    </li>
    <!--/ User Dropdown -->
  </ul>
</div>
