import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";

import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  // Public
  public apiData = [];
  public onApiDataChange: BehaviorSubject<any>;

  private;

  /**
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) {}

  /**
   * Get Notifications Data
   */
  getNotificationsData(payload: {
    pageNo: number;
    limit: number;
  }): Observable<any> {
    return this._httpClient.get<any>(
      environment.apiHost + "get_all_notifications?page=" + payload.pageNo
    );
  }

  getNotificationsWatchListData(payload: {
    pageNo: number;
    limit: number;
   
  }): Observable<any> {
    let params = new HttpParams();
    params = params.append('page', `${payload.pageNo}`);
    params = params.append('keyword','watchlist' );
    const endPoint = `get_all_notifications`
    return this._httpClient.get<any>(
      environment.apiHost + endPoint,{params}
    );
  }

  markAsRead(payload: any): Observable<any> {
    return this._httpClient.post<any>(
      environment.apiHost + "mark_notification_read",
      payload
    );
  }

  deleteNotification(payload: any): Observable<any> {
    return this._httpClient.post<any>(
      environment.apiHost + "delete_notifications",
      payload
    );
  }
}
