import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  public myData = new BehaviorSubject([]);

  public image: Observable<any> = this.myData.asObservable();

  constructor(private http: HttpClient) {}
  getProfile() {
    const apiName = 'dashboard/profile';

    return this.http.get(environment.apiHost + apiName);
  }

  updateProfile(data,id) {
    const apiName = `dashboard/profile/${id}/`;
    return this.http.put(`${environment.apiHost}${apiName}`, data);
  }
 
  updateAdminProfile(data) {
    const apiName = 'edit_profile/';
    return this.http.post(environment.apiHost + apiName, data);
  }

  changePassword(data) {
    const apiName = 'user/changepasssword/';
    return this.http.post(`${environment.apiHost}`+'user/changepasssword/', data);
  }

  savePrefences(payload): Observable<any> {
    const apiName = '';
    return this.http.post<any>(environment.apiHost + apiName, payload);
  }

  checkUserExistence(data) {
    const apiName = 'email_check/';
    return this.http.post<any>(environment.apiHost + apiName, { email: data });
  }

  createVendorRequestemail(data): Observable<any> {
    const formData = new FormData();
    formData.append('email', data.email);
    formData.append('customer_quote_default', data.customer_quote_default);
    formData.append('vendor_quote_default', data.vendor_quote_default);
    formData.append('no_quote_default', data.no_quote_default);
    formData.append('custom_reply_default', data.custom_reply_default);
    formData.append('password', data.password);
    formData.append('signature_name', data.signature_name);
    formData.append('signature_file', data.signature_filee);
    formData.append('email_type', data.email_type);

    if(data.email_type == 'microsoft'){
      formData.append('company_email', data.company_email);
    }
    const apiName = 'vendor_emails/add';
    return this.http.post<any>(environment.apiHost + apiName, formData);
  }

  UpdateVendorRequestEmail(details): Observable<any> {
    const apiName = 'vendor_emails/update';
    return this.http.post<any>(environment.apiHost + apiName, details);
  }

  getVendorRequestEmails(payload): Observable<any> {
    const apiName = 'vendor_emails';
    return this.http.post<any>(environment.apiHost + apiName, payload);
  }

  DeleteVendorRequestEmail(id): Observable<any> {
    const apiName = 'vendor_emails/delete';
    return this.http.post<any>(environment.apiHost + apiName, { id });
  }

  getProfileInformation(payload?): Observable<any> {
    const endPoint = 'get_profile_user_data';
    return this.http.get<any>(environment.apiHost + endPoint);
  }

  saveProfileInfo(payload): Observable<any> {
    const endPoint = 'update_profile_user_data';
    return this.http.post<any>(environment.apiHost + endPoint, payload);
  }

  getVendorParams(): Observable<any> {
    const endPoint = 'usa_vendor_parameters_get';
    return this.http.get<any>(environment.apiHost + endPoint);
  }

  savaVendorParams(payload): Observable<any> {
    const endPoint = 'usa_vendor_parameters_add';
    return this.http.post<any>(environment.apiHost + endPoint, payload);
  }

  getAllCurrencies(): Observable<any> {
    const endPoint = 'get_currency_exchange_rates';
    return this.http.post<any>(environment.apiHost + endPoint, {});
  }

  getUserCurrency(): Observable<any> {
    const endPoint = 'get_user_default_currency';
    return this.http.post<any>(environment.apiHost + endPoint, {});
  }

  getUserCustomTemplate(): Observable<any> {
    const endPoint = 'get_user_custom_template/';
    return this.http.get<any>(environment.apiHost + endPoint);
  }

  saveUserCustomTemplate(quote_template, no_quote_template): Observable<any> {
    let payload = {
      quote_template: quote_template,
      no_quote_template: no_quote_template,
    };
    const endPoint = 'save_user_custom_template';
    return this.http.post<any>(environment.apiHost + endPoint, payload);
  }

  getExport(): Observable<any> {
    const apiName = 'export_file/prices/csv/';
    return this.http.get<any>(environment.apiHost + apiName);
  }

  reGenerate(): Observable<any> {
    const apiName = 'export_file/task/trigger/prices/csv/';
    return this.http.post<any>(environment.apiHost + apiName,{});
  }

  downloadData(url: any) {
    return new Promise((resolve, reject) => {
      this.http.get(url, { responseType: 'blob' }).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

}
