import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { NotificationsService } from 'app/layout/components/navbar/navbar-notification/notifications.service';
import { AuthenticationService } from 'app/pages/auth/services/authentication.service';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';

// Interface
interface notification {
  email_account: string;
  email_subject: string;
  email_uuid: string;
  error_msg: string;
  id: any;
  is_active: boolean;
  is_read: boolean;
  recipient_email: string;
  sender_email: string;
  time_create: any;
  time_update: any;
  title: string;
}

interface notificationData {
  notifications: notification[];
  totalCount: number;
  unreadNotification: number;
}

@Component({
  selector: 'app-navbar-watchlist',
  templateUrl: './navbar-watchlist.component.html',
  styleUrls: ['./navbar-watchlist.component.scss']
})


export class NavbarWatchlistComponent implements OnInit {
  // Public
  public notifications: notification[] = [];
  public totalUnreadNotifications: number = 0;
  // public hoverElement = document.getElementById("hover-element");
  // public clickElement = document.getElementById("click-element");

  // @ViewChild('hover-element', { static: false }) hoverElement!: ElementRef;
  @ViewChild('navbarNotificationDropdown', { static: false }) clickElement!: ElementRef;

  /**
   *
   * @param {NotificationsService} _notificationsService
   */
  constructor(
    private _notificationsService: NotificationsService,
    private authenticationService: AuthenticationService,
    private toasterService: ToastrService
  ) {}

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    let payload = {
      pageNo: 1,
      limit: 0,
      keyword:"watchlist"
    };
    this.authenticationService.currentUser.subscribe((user) => {
     
    });
  }

  triggerClick(){
    console.log("hover")
    this.clickElement.nativeElement.click();
  }


  markMessageAsRead(notification: notification, index: number) {
    const payload = {
      id_list: [notification.id],
    };
    this._notificationsService.markAsRead(payload).subscribe(
      (res) => {
        if (res.status) {
          this.notifications[index].is_read = true;
          let unread = _.filter(
            this.notifications,
            (not: notification) => !not.is_read
          );
          this.totalUnreadNotifications = unread.length;
        } else {
        }
      },
      (error) => {}
    );
  }

  markAllAsRead() {
    const unread = _.filter(
      this.notifications,
      (noti: notification) => !noti.is_read
    );
    const ids = [];
    _.forEach(unread, (noti: notification) => {
      ids.push(noti.id);
    });
    const payload = {
      id_list: ids,
    };
    this._notificationsService.markAsRead(payload).subscribe(
      (res) => {
        if (res.status) {
          _.forEach(this.notifications, (noti: notification) => {
            noti.is_read = true;
          });
          let unread = _.filter(
            this.notifications,
            (not: notification) => !not.is_read
          );
          this.totalUnreadNotifications = unread.length;
        } else {
        }
      },
      (error) => {}
    );
  }

  deleteNotification(notification) {
    const payload = {
      id_list: [notification.id],
    };
    this._notificationsService.deleteNotification(payload).subscribe(
      (res) => {
        if (res.status) {
          console.log('res', res);
          this.notifications = _.filter(
            this.notifications,
            (p) => p.id != notification.id
          );
          this.toasterService.success(
            'Notification deleted succesfully',
            'Success!!',
            {
              toastClass: 'toast ngx-toastr',
              progressBar: true,
              closeButton: true,
              timeOut: 3000,
            }
          );
        } else {
          this.toasterService.error(res.message, 'Oops!!', {
            toastClass: 'toast ngx-toastr',
            progressBar: true,
            closeButton: true,
            timeOut: 3000,
          });
        }
      },
      (error) => {
        this.toasterService.error(
          'Something wents wrong!! Try again',
          'Oops!!',
          {
            toastClass: 'toast ngx-toastr',
            progressBar: true,
            closeButton: true,
            timeOut: 3000,
          }
        );
      }
    );
  }
}
