import { Role } from './role';

export class User {
	id: number;
	email: string;
	password: string;
	firstName: string;
	lastName: string;
	profile_pic: string;
	avatar: string;
	role: Role;
	token?: string;
	company: string;
	first_name:string;
	last_name:string;
	profile_pic_base64: string;
}
