import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'environments/environment';
import { AuthenticationService } from 'app/pages/auth/services/authentication.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
	/**
	 *
	 * @param {AuthenticationService} _authenticationService
	 */
	constructor(private _authenticationService: AuthenticationService) {}

	/**
	 * Add auth header with jwt if user is logged in and request is to api url
	 * @param request
	 * @param next
	 */
	// intercept(
	// 	request: HttpRequest<any>,
	// 	next: HttpHandler
	// ): Observable<HttpEvent<any>> {
	// 	const currentUser = this._authenticationService.currentUserValue;
	// 	const isLoggedIn = currentUser && currentUser.token;
	// 	const isApiUrl = request.url.startsWith(environment.apiUrl);
	// 	if (
	// 		this._authenticationService.getToken() &&
	// 		!request.url.includes(environment.s3BucketURL)
	// 	) {
	// 		request = request.clone({
	// 			setHeaders: {
	// 				Authorization: `Token ${this._authenticationService.getToken()}`,
	// 			},
	// 		});
	// 	}
	// 	return next.handle(request);
	// }
	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		const currentUser = this._authenticationService.isSessionActive();
		const isLoggedIn = this._authenticationService.isLoggedIn;
		console.log(isLoggedIn)
		const isApiUrl = request.url.startsWith(environment.apiUrl);
		console.log(isLoggedIn)
		if (currentUser) {
			request = request.clone({
				setHeaders: {
					Authorization: `Token ${currentUser}`,
				},
			});
		}

		return next.handle(request);
	}
}
