import {
	Component,
	OnDestroy,
	OnInit,
	HostBinding,
	HostListener,
	ViewEncapsulation,
	AfterViewInit,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';

import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { CoreConfigService } from '@core/services/config.service';
import { CoreMediaService } from '@core/services/media.service';
import { LocalStorageService } from '../../../../@core/services/local-storage/local-storage.service';
import { User } from 'app/core/models';

import { Router } from '@angular/router';
import { SESSION_TOKENS_REF } from '../../../../@core/constants/index';
import { AuthenticationService } from 'app/pages/auth/services/authentication.service';
import { ProfileService } from 'app/pages/profile/services/profile.service';
import { coreConfig } from 'app/app-config';
import { CookieService } from 'ngx-cookie-service';

declare function googleTranslateElementInit(): any;

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class NavbarComponent implements OnInit, OnDestroy {
	public horizontalMenu: boolean;
	public hiddenMenu: boolean;

	public coreConfig: any;
	public currentSkin: string;
	public prevSkin: string;

	public currentUser: User;
	public languageOptions: any;
	public navigation: any;
	public selectedLanguage: any;

	public searchLanguageResults: any[];
	public allLanguages: any[] = [];
	public languageSearch: string = '';
	public exportList: Array<any> = [];
	exportLoading:boolean =false;
	generateLoading:boolean=false;
	profilePic: string;

	public csv={
		task_status:'',
		s3_url:''
	}


	@HostBinding('class.fixed-top')
	public isFixed = false;

	@HostBinding('class.navbar-static-style-on-scroll')
	public windowScrolled = false;

	// Add .navbar-static-style-on-scroll on scroll using HostListener & HostBinding
	@HostListener('window:scroll', [])
	onWindowScroll() {
		if (
			(window.pageYOffset ||
				document.documentElement.scrollTop ||
				document.body.scrollTop > 100) &&
			this.coreConfig.layout.navbar.type == 'navbar-static-top' &&
			this.coreConfig.layout.type == 'horizontal'
		) {
			this.windowScrolled = true;
		} else if (
			(this.windowScrolled && window.pageYOffset) ||
			document.documentElement.scrollTop ||
			document.body.scrollTop < 10
		) {
			this.windowScrolled = false;
		}
	}

	// Private
	private _unsubscribeAll: Subject<any>;

	/**
	 * Constructor
	 *
	 * @param {Router} _router
	 * @param {AuthenticationService} _authenticationService
	 * @param {CoreConfigService} _coreConfigService
	 * @param {CoreSidebarService} _coreSidebarService
	 * @param {CoreMediaService} _coreMediaService
	 * @param {MediaObserver} _mediaObserver
	 * @param {TranslateService} _translateService
	 */
	constructor(
		private _router: Router,
		private _authenticationService: AuthenticationService,
		private _coreConfigService: CoreConfigService,
		private _coreMediaService: CoreMediaService,
		private _coreSidebarService: CoreSidebarService,
		private _mediaObserver: MediaObserver,
		public _translateService: TranslateService,
		private localStorage: LocalStorageService,
		private profileService: ProfileService,
		private cookieService: CookieService
	) {
		this._authenticationService.currentUser.subscribe(
			(x) => (this.currentUser = x)
		);

		this.languageOptions = [
			{
				title: 'English',
				flag: 'us',
				value: 'en'
			},
			{
				title: 'Spanish',
				flag: 'es',
				value: 'es'
			},
			{
				title: 'Chinese',
				flag: 'cn',
				value: 'zh-CN'
			}
		];

		// Set the private defaults
		this._unsubscribeAll = new Subject();
	}

	// Public Methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Toggle sidebar open
	 *
	 * @param key
	 */
	toggleSidebar(key): void {
		this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
	}

	/**
	 * Set the language
	 *
	 * @param language
	 */

	setLanguage(language): void {
		// Set the selected language for the navbar on change
		this.selectedLanguage = language;

		// Use the selected language id for translations
		this._translateService.use(language);

		this._coreConfigService.setConfig(
			{ app: { appLanguage: language } },
			{ emitEvent: true }
		);

		// this.setCookie('googtrans', '/en/' + language, 1);
		this.cookieService.set('googtrans', '/en/'+language);
		window.location.reload();

		// const select = document.getElementsByClassName('goog-te-combo');
		// select[0].childNodes.forEach((item: Element) => {
		// 	if (item.attributes[0].value === language) {
		// 		item.setAttribute('selected', 'true');
		// 	}
		// });
		// select[0].dispatchEvent(new Event('change'));

		// this.languageSearch = '';
		// this.searchLanguageResults = [];

	}

	get getCurrentLanguage(){
		if(this.allLanguages && this.allLanguages?.length > 0){
			let current = this.allLanguages.filter((lang)=> lang.flag == this._translateService.currentLang);
			if(current.length > 0){
				return {
					title: current[0].flag == 'zh-CN' ? 'Chinese' : current[0].title,
					flag: (current[0].flag == 'en' ? 'us': (current[0].flag == 'zh-CN' ? 'cn': current[0].flag))
				}
			}else{
				return {
					title: 'English',
					flag: 'us'
				}
			}
		}
		return {
			title: '',
			flag: ''
		}
	}

	onSearchLanguage(){
		this.searchLanguageResults = this.allLanguages.filter((lang)=> (lang.title.toLowerCase()).includes(this.languageSearch.toLowerCase()));	
	}

	private setCookie(name: string, value: string, expireDays: number, path: string = '') {
		let d: Date = new Date();
		d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
		let expires: string = `expires=${d.toUTCString()}`;
		let cpath: string = path ? `; path=${path}` : '';
		document.cookie = `${name}=${value}; ${expires}${cpath}`;
	}

	/**
	 * Toggle Dark Skin
	 */
	toggleDarkSkin() {
		// Get the current skin
		this._coreConfigService
			.getConfig()
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((config) => {
				this.currentSkin = config.layout.skin;
			});

		// Toggle Dark skin with prevSkin skin
		this.prevSkin = localStorage.getItem('prevSkin');

		if (this.currentSkin === 'dark') {
			this._coreConfigService.setConfig(
				{
					layout: { skin: this.prevSkin ? this.prevSkin : 'default' },
				},
				{ emitEvent: true }
			);
		} else {
			localStorage.setItem('prevSkin', this.currentSkin);
			this._coreConfigService.setConfig(
				{
					layout: { skin: 'dark' },
				},
				{ emitEvent: true }
			);
		}
	}

	/**
	 * Logout method
	 */
	logout() {

		this._authenticationService.logout().subscribe((res) => {
			// if (res) {
				this.localStorage.removeItem(SESSION_TOKENS_REF);
				this.localStorage.removeItem('token');
				localStorage.clear()
				this.localStorage.removeItem('module-permissions');
				this._router.navigate(['/login']);
		});
	}

	downloadLink(){
		if(this.csv.task_status != 'completed') return
		this.exportLoading=true;
		this.profileService
				.downloadData(this.csv.s3_url)
				.then((blob: Blob) => {
					this.exportLoading=false;
				  const a = document.createElement('a');
				  const objectUrl = URL.createObjectURL(blob);
				  a.href = objectUrl;
				  a.download = this.csv.s3_url;
				  a.click();
				  URL.revokeObjectURL(objectUrl);
				});
	}

	getExportLink(){
		
		this.profileService.getExport().subscribe((res)=>{
			if(res){
				// this.profileService
				// .downloadData(res?.response?.s3_url)
				// .then((blob: Blob) => {
				// 	this.exportLoading=false;
				//   const a = document.createElement('a');
				//   const objectUrl = URL.createObjectURL(blob);
				//   a.href = objectUrl;
				//   a.download = res.response?.s3_url;
				//   a.click();
				//   URL.revokeObjectURL(objectUrl);
				// });
				this.csv={
					task_status:res.response.task_status,
					s3_url:res.response.s3_url
				}
				// this.downloadLink()
			}
		})

	}

	reGenerate(){
		this.profileService.reGenerate().subscribe({
			next:(res)=>{
				if(res.status){
					// this.getExportLink()
				}
			}
		})
	}

	ngOnInit(): void {

		// this.getExportLink()
		
		// get the currentUser details from localStorage
		this.profileService.image.subscribe((res) => {
			this.currentUser = JSON.parse(this.localStorage.getItem('currentUser'));
			//console.log(res.response)
		});

		setTimeout(()=>{
			const select = document.getElementsByClassName('goog-te-combo');
			select[0]?.childNodes.forEach((item: Element) => {
				// console.log(item.textContent);
				let language = {
					title: item.textContent,
					flag: item.attributes[0].value
				}
				this.allLanguages.push(language);
			});
		}, 2000)

		this.currentUser = JSON.parse(this.localStorage.getItem('currentUser'));
		// console.log(this.currentUser);
		// Subscribe to the config changes
		this._coreConfigService.config
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((config) => {
				this.coreConfig = config;
				this.horizontalMenu = config.layout.type === 'horizontal';
				this.hiddenMenu = config.layout.menu.hidden === true;
				this.currentSkin = config.layout.skin;

				// Fix: for vertical layout if default navbar fixed-top than set isFixed = true
				if (this.coreConfig.layout.type === 'vertical') {
					setTimeout(() => {
						if (this.coreConfig.layout.navbar.type === 'fixed-top') {
							this.isFixed = true;
						}
					}, 0);
				}
			});

		// Horizontal Layout Only: Add class fixed-top to navbar below large screen
		if (this.coreConfig.layout.type == 'horizontal') {
			// On every media(screen) change
			this._coreMediaService.onMediaUpdate
				.pipe(takeUntil(this._unsubscribeAll))
				.subscribe(() => {
					const isFixedTop = this._mediaObserver.isActive('bs-gt-xl');
					if (isFixedTop) {
						this.isFixed = false;
					} else {
						this.isFixed = true;
					}
				});
		}
		// Set the selected language from default languageOptions
		this.selectedLanguage = _.find(this.languageOptions, {
			id: this._translateService.currentLang,
		});
		this.profileService.getProfile().subscribe((res: any) => {
			this.profilePic = res[0].profile_pic;
		});
		// this.getExportLink();
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}
}
