import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment as env, environment } from 'environments/environment';
import { User, Role } from 'app/core/models';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { SESSION_TOKENS_REF } from '../../../../@core/constants';
import { LocalStorageService } from '../../../../@core/services/local-storage/local-storage.service';
import { JwtHelperService } from '@auth0/angular-jwt';

const helper = new JwtHelperService();

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
	loggedUser: string;
	isLoggedIn: boolean =false;
	//public
	public currentUser: Observable<User>;

	//private
	private currentUserSubject: BehaviorSubject<User>;

	/**
	 *
	 * @param {HttpClient} http
	 * @param {ToastrService} _toastrService
	 */
	constructor(
		private http: HttpClient,
		private _toastrService: ToastrService,
		private localStorage: LocalStorageService,
		private route: Router
	) {
		this.currentUserSubject = new BehaviorSubject<User>(
			JSON.parse(localStorage.getItem('currentUser'))
		);

		this.currentUser = this.currentUserSubject.asObservable();
	}

	// getter: currentUserValue
	public get currentUserValue(): User {
		return this.currentUserSubject.value;
	}

	/**
	 *  Confirms if user is admin
	 */
	get isAdmin() {
		return (
			this.currentUser && this.currentUserSubject.value.role === Role.Admin
		);
	}

	/**
	 *  Confirms if user is client
	 */
	get isClient() {
		return (
			this.currentUser && this.currentUserSubject.value.role === Role.Client
		);
	}
	set token(token: string) {
		console.log(token)
		this.isLoggedIn = true;
		localStorage.setItem(SESSION_TOKENS_REF, token);
	  }
	
	  /**
	   * @description Get the logged in user token.
	   */
	  get token() {
		let token:any = localStorage.getItem(SESSION_TOKENS_REF);
		return token;
	  }
	
	/**
	 * User login
	 *
	 * @param email
	 * @param password
	 * @returns user
	 */
	checkToken(): boolean {
		return (
			!!this.localStorage.getItem(SESSION_TOKENS_REF) &&
			this.checkExpiredToken()
		);
	}
	isSessionActive() {
		this.isLoggedIn = true;
		return localStorage.getItem(SESSION_TOKENS_REF);
	  }
	checkExpiredToken() {
		// Token is ok - return true
		const tokens = this.localStorage.getItem(SESSION_TOKENS_REF);
		if (tokens) {
			const myRawToken = tokens.access;
			return myRawToken && !helper.isTokenExpired(myRawToken);
		}
		// Token is not ok - return false
		return false;
	}

	setToken(tokens): void {
		this.localStorage.setItem(SESSION_TOKENS_REF, tokens);
		let userData = {...tokens.user_details,...tokens.userData}
				this.localStorage.setItem(
			'currentUser',
			JSON.stringify(userData)
		);
		this.currentUserSubject.next(tokens.user_details);
	}

	getToken(): boolean {
		if (this.checkToken()) {
			return this.localStorage.getItem(SESSION_TOKENS_REF).access;
		}
		return false;
	}

	getTokenRefresh(): boolean {
		if (this.checkToken()) {
			return this.localStorage.getItem(SESSION_TOKENS_REF).refresh;
		}
		return false;
	}

	login(data): Observable<any> {
		return this.http.post<any>(env.apiHost + 'user/login/', data);
	}

	getCurrentUser() {
		return this.localStorage.getItem(SESSION_TOKENS_REF).user;
	}

	// goto: Temporary implementation of refresh()
	refresh(): Observable<any> {
		return this.http.post<any>(env.apiHost + 'user/refresh', {
			refresh: this.getTokenRefresh(),
		});
	}

	logout(): Observable<any> {
		return this.http.post<any>(env.apiHost + 'user/logout/', {});
	}

	getServerInfo(): Observable<any> {
		return this.http.post<any>(env.apiHost + 'server_info/get', {});
	}
	getWindowImage(): Observable<any> {
		return this.http.get<any>(env.apiHost + 'window_image/', {});
	}

	getCompanyLogo(): Observable<any> {
		return this.http.get<any>(env.apiHost + 'company_logo/', {});
	}

	getUserModulePermissions(): Observable<any>{
		return this.http.get(env.apiHost+'get_all_user_modules/');
	}

	changePassword(password,username): Observable<any> {
		const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Custom-Header', 'value');

		let userData = {
			newpassword : password,
			username: username,
			password: ''
		}
			const uploadData = new FormData();
		uploadData.append('user',JSON.stringify(userData));
		return this.http.post<any>(env.apiHost + 'user/changepasssword/', userData);
	}
	forgotPassword(data): Observable<any>{
		return this.http.post<any>(env.apiHost1 + 'password_reset/', data);
	}
}
