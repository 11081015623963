<li ngbDropdown class="nav-item dropdown-notification mr-25">
  <a class="nav-link" ngbDropdownToggle id="navbarNotificationDropdown"
    ><i class="ficon" data-feather="bell"></i
    ><span class="badge badge-pill badge-danger badge-up"
      >{{ totalUnreadNotifications }}</span></a>
  <ul
    ngbDropdownMenu
    aria-labelledby="navbarNotificationDropdown"
    class="dropdown-menu dropdown-menu-media dropdown-menu-right"
  >
    <!-- Notifications header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">Notifications</h4>
        <div class="badge badge-pill badge-light-primary">
          {{ totalUnreadNotifications }} New
        </div>
      </div>
    </li>
    <!--/ Notifications header -->

    <!-- Notifications content -->
    <li class="scrollable-container media-list" [perfectScrollbar]>
      <!-- <a class="d-flex"
          *ngFor="let notification of notifications; let index = index">
        <div class="media d-flex align-items-start">
          <div class="media-left">
            <div class="avatar">
              <div
              class="avatar bg-light-warning">
              <div class="avatar-content">
                <i class="avatar-icon" [data-feather]="'home'"></i>
              </div>
            </div>
            </div>
          </div>
          <div class="media-body">
            <p class="media-heading">{{notification?.title}}</p>
            <small class="notification-text">{{notification.sender_email}}</small>
          </div>
        </div></a> -->

      <!-- <div class="media d-flex align-items-center">
        <h6 class="font-weight-bolder mr-auto mb-0">System Notifications</h6>
        <div class="custom-control custom-control-primary custom-switch">
          <input
            class="custom-control-input"
            id="systemNotification"
            type="checkbox"
            [checked]="notifications.system"
          />
          <label class="custom-control-label" for="systemNotification"></label>
        </div>
      </div> -->
      <a
        class="d-flex"
        href="javascript:void(0)"      
        *ngFor="let notification of notifications; let index = index"
        [style.background-color]="notification.is_read ? '' : '#efefef'"
      >
        <div class="media d-flex align-items-start">
          <div class="media-left">
            <div
              class="avatar bg-light-warning">
              <div class="avatar-content">
                <i class="avatar-icon" [data-feather]="'alert-triangle'"></i>
              </div>
            </div>
          </div>
          <div class="media-body">
            <p class="media-heading"
               [style.font-weight]="notification.is_read ? '' : '700'">{{notification?.title}}</p>
            <small class="notification-text"
                   [style.font-weight]="notification.is_read ? '' : '700'"
                   *ngIf="notification?.sender_email">Sender: {{ notification.sender_email }} <br> </small>
            <small class="notification-text"
                   [style.font-weight]="notification.is_read ? '' : '700'"
                   *ngIf="notification?.email_account">Email Account: {{ notification.email_account }} <br></small>
            <small class="notification-text"
                   [style.font-weight]="notification.is_read ? '' : '700'"
                   *ngIf="notification?.email_subject">Subject: {{ notification?.email_subject.length > 45 ? ((notification?.email_subject | slice:0:42)+'...')  : notification?.email_subject }}<br></small>
            
                   <small
                   class="notification-text"
                   [style.font-weight]="notification.is_read ? '' : '700'"
                   *ngIf="notification?.type === 'watchlist' "
                   >
                   {{
                     notification?.message
                   }}<br
                 /></small>
                 
                   <small class="notification-text"
                   [style.font-weight]="notification.is_read ? '' : '700'"
                   *ngIf="notification?.time_create">Date: {{ notification?.time_create | date: 'dd/MM/yyyy'  }}</small>

                  

          </div>
          <div class="media-right">
            <div ngbTooltip="Delete" (click)="deleteNotification(notification)"><i
            data-feather="trash-2"
            class="text-primary cursor-pointer i-enhanced"
          ></i></div>
          <div *ngIf="notification.is_read == false" ngbTooltip="markasread" (click)="markMessageAsRead(notification, index)"><i
											data-feather="check"
											class="text-primary cursor-pointer i-enhanced"
										></i></div></div>
        </div></a
      >
    </li>
    <!--/ Notifications content -->

    <!-- Notifications footer -->
    <li class="dropdown-menu-footer">
      <a class="btn btn-primary btn-block" (click)="markAllAsRead()" href="javascript:void(0)"
        >Read all notifications</a
      >
    </li>
    <!--/ Notifications footer -->
  </ul>
</li>
