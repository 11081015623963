<div class="dd-notify">
  <li class="nav-item d-none d-lg-block" (mouseenter)="triggerClick()" >
    <a
      class="nav-link"
      placement="bottom"
      ngbTooltip="Watch"
      routerLink="/watch"
    >
      <span data-feather="watch" [class]="'ficon'"></span>
    </a>
  </li>
  <div class="watchlistNotif">
    <li ngbDropdown class="nav-item hiddenIco dropdown-notification mr-25">
      <!-- trigger click here to show dropdown  -->
      <a class="nav-link" ngbDropdownToggle id="navbarNotificationDropdown" #navbarNotificationDropdown
        ><i class="ficon" data-feather="watch"></i
        ><span class="badge badge-pill badge-danger badge-up">{{
          totalUnreadNotifications
        }}</span></a
      >

      <ul
        ngbDropdownMenu
        aria-labelledby="navbarNotificationDropdown"
        class="dropdown-menu dropdown-menu-media"
      >
        <!-- Notifications header -->
        <li class="dropdown-menu-header">
          <div class="dropdown-header d-flex">
            <h4 class="notification-title mb-0 mr-auto">Notifications</h4>
            <div class="badge badge-pill badge-light-primary">
              {{ totalUnreadNotifications }} New
            </div>
          </div>
        </li>
        <!--/ Notifications header -->

        <!-- Notifications content -->
        <li class="scrollable-container media-list" [perfectScrollbar]>
          <a
            class="d-flex"
            href="javascript:void(0)"
            *ngFor="let notification of notifications; let index = index"
            [style.background-color]="notification.is_read ? '' : '#efefef'"
          >
            <div class="media d-flex align-items-start">
              <div class="media-left">
                <div class="avatar bg-light-warning">
                  <div class="avatar-content">
                    <i
                      class="avatar-icon"
                      [data-feather]="'watch'"
                    ></i>
                  </div>
                </div>
              </div>
              <div class="media-body">
                <p
                  class="media-heading"
                  [style.font-weight]="notification.is_read ? '' : '700'"
                >
                  {{ notification?.title }}
                </p>
                <small
                  class="notification-text"
                  [style.font-weight]="notification.is_read ? '' : '700'"
                  *ngIf="notification?.sender_email"
                  >Sender: {{ notification.sender_email }} <br />
                </small>
                <small
                  class="notification-text"
                  [style.font-weight]="notification.is_read ? '' : '700'"
                  *ngIf="notification?.email_account"
                  >Email Account: {{ notification.email_account }} <br
                /></small>
                <small
                  class="notification-text"
                  [style.font-weight]="notification.is_read ? '' : '700'"
                  *ngIf="notification?.message"
                  >
                  {{
                    notification?.message
                  }}<br
                /></small>
                <small
                  class="notification-text"
                  [style.font-weight]="notification.is_read ? '' : '700'"
                  *ngIf="notification?.time_create"
                  >Date:
                  {{ notification?.time_create | date : "dd/MM/yyyy" }}</small
                >
              </div>
              <div class="media-right">
                <div
                  ngbTooltip="Delete"
                  (click)="deleteNotification(notification)"
                >
                  <i
                    data-feather="trash-2"
                    class="text-primary cursor-pointer i-enhanced"
                  ></i>
                </div>
                <div
                  *ngIf="notification.is_read == false"
                  ngbTooltip="markasread"
                  (click)="markMessageAsRead(notification, index)"
                >
                  <i
                    data-feather="check"
                    class="text-primary cursor-pointer i-enhanced"
                  ></i>
                </div>
              </div></div
          ></a>
        </li>
        <!--/ Notifications content -->

        <!-- Notifications footer -->
        <li class="dropdown-menu-footer">
          <a
            class="btn btn-primary btn-block"
            (click)="markAllAsRead()"
            href="javascript:void(0)"
            >Read all notifications</a
          >
        </li>
        <!--/ Notifications footer -->
      </ul>
    </li>
  </div>
</div>
