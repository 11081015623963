import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/helpers/auth.guards';
import { LoginGuard } from './core/helpers/login.guard';

const appRoutes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/auth/auth.module').then((m) => m.AuthModule),
    canActivate: [LoginGuard],
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./pages/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
    canActivate: [AuthGuard],
    data: {
      role: ['admin', 'admin_user'],
      title: 'Dashboard',
    },
  },
  {
    path: 'add/vendor',
    loadChildren: () =>
      import('./pages/vendor-price/vendor-price.module').then((m) => m.VendorPriceModule),
  },
  {
    path: 'azure-oauth/callback',
    loadChildren: () =>
      import('./pages/azure-auth/azure-auth.module').then(
        (m) => m.AzureAuthModule
      ),
    // canActivate: [AuthGuard],
    data: {
      role: ['Dashboard'],
      title: 'Azure',
    },
  },

  {
    path: 'customer',
    loadChildren: () =>
      import('./pages/customer-settings/customer-settings.module').then(
        (m) => m.CustomerSettingsModule
      ),
    canActivate: [AuthGuard],
    data: {
      role: ['admin', 'admin_user'],
      title: 'Customer Settings',
    },
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./pages/settings/settings.module').then((m) => m.SettingsModule),
    canActivate: [AuthGuard],
    data: {
      role: ['admin'],
      title: 'Settings',
    },
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./pages/profile/profile.module').then((m) => m.ProfileModule),
    canActivate: [AuthGuard],
    data: {
      role: ['admin', 'admin_user'],
      title: 'Profile',
    },
  },
  {
    path: 'user',
    loadChildren: () =>
      import('./pages/user/user.module').then((m) => m.UserModule),
    canActivate: [AuthGuard],
    data: {
      role: ['admin'],
      title: 'User',
    },
  },
  {
    path: 'jitbit',
    loadChildren: () =>
      import('./pages/jitbit/jitbit.module').then((m) => m.JitbitModule),
    canActivate: [AuthGuard],
    data: {
      role: ['admin', 'admin_user'],
      title: 'JitBit',
    },
  },
  {
    path: 'watch',
    loadChildren: () =>
      import('./pages/watch/watch.module').then((m) => m.WatchModule),
    canActivate: [AuthGuard],
    data: {
      role: ['admin', 'admin_user'],
      title: 'Watch',
    },
  },
  {
    path: 'not-found',
    loadChildren: () =>
      import('./pages/not-found/not-found.module').then(
        (m) => m.NotFoundModule
      ),
  },
  { path: '**', redirectTo: '/not-found', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
