import { NgModule } from '@angular/core';

import { CoreMenuModule } from '@core/components';
import { CoreCommonModule } from '@core/common.module';

import { HorizontalMenuComponent } from 'app/layout/components/menu/horizontal-menu/horizontal-menu.component';
import { RouterModule } from '@angular/router';

@NgModule({
	declarations: [HorizontalMenuComponent],
	imports: [CoreMenuModule, CoreCommonModule, RouterModule],
	exports: [HorizontalMenuComponent],
})
export class HorizontalMenuModule {}
