import { Component, OnInit } from '@angular/core';

import { NotificationsService } from 'app/layout/components/navbar/navbar-notification/notifications.service';
import { AuthenticationService } from 'app/pages/auth/services/authentication.service';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';

// Interface
interface notification {
  email_account: string;
  email_subject: string;
  email_uuid: string;
  error_msg: string;
  id: any;
  is_active: boolean;
  is_read: boolean;
  recipient_email: string;
  sender_email: string;
  time_create: any;
  time_update: any;
  title: string;
}

interface notificationData {
  notifications: notification[];
  totalCount: number;
  unreadNotification: number;
}

@Component({
  selector: 'app-navbar-notification',
  templateUrl: './navbar-notification.component.html',
})
export class NavbarNotificationComponent implements OnInit {
  // Public
  public notifications: notification[] = [];
  public totalUnreadNotifications: number = 0;

  /**
   *
   * @param {NotificationsService} _notificationsService
   */
  constructor(
    private _notificationsService: NotificationsService,
    private authenticationService: AuthenticationService,
    private toasterService: ToastrService
  ) {}

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    let payload = {
      pageNo: 1,
      limit: 0,
    };
    this.authenticationService.currentUser.subscribe((user) => {
      if (user) {
      
      }
    });
  }

  markMessageAsRead(notification: notification, index: number) {

  }

  markAllAsRead() {
    const unread = _.filter(
      this.notifications,
      (noti: notification) => !noti.is_read
    );
    const ids = [];
    _.forEach(unread, (noti: notification) => {
      ids.push(noti.id);
    });
    const payload = {
      id_list: ids,
    };

  }

  deleteNotification(notification) {
    const payload = {
      id_list: [notification.id],
    };
 
  }
}
