import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpResponse,
	HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { SESSION_TOKENS_REF } from '@core/constants/index';
import { AuthenticationService } from 'app/pages/auth/services/authentication.service';
import { LocalStorageService } from '@core/services/local-storage/local-storage.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
	currentUrl: string;
	/**
	 * @param {Router} _router
	 * @param {AuthenticationService} _authenticationService
	 */
	constructor(
		private _router: Router,
		private _authenticationService: AuthenticationService,
		private localStorage: LocalStorageService,
		private router: Router
	) {
		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
			  this.currentUrl=event.url
			}
		  });
	}

	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			tap(
				(event)=>{
					if (event instanceof HttpResponse) {
						if((event.body.status_code == 401 || event.body.status_code == 403) && (!this.currentUrl.includes("change-password") || !this.currentUrl.includes("forgot-password") || !this.currentUrl.includes("login")) ){
							this.logoutandRedirect();
						}
					}
				},(error)=>{
					if (error instanceof HttpErrorResponse) {
						if((error.status === 401 || error.status === 403) && (!this.currentUrl.includes("change-password") || !this.currentUrl.includes("forgot-password") || !this.currentUrl.includes("login"))){
							this.logoutandRedirect();
						}
					}
				}
			)
		);
	}

	logoutandRedirect(){	
		this.localStorage.removeItem(SESSION_TOKENS_REF);
		this.localStorage.removeItem('currentUser');
		this._authenticationService.isLoggedIn = false;
				this.localStorage.removeItem('token');
				localStorage.clear()
		localStorage.removeItem('config');
		this.router.navigate(['/login']);	
	}
}
