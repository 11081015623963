import { CoreMenu } from '@core/types';

//? DOC: http://localhost:7777/demo/vuexy-angular-admin-dashboard-template/documentation/guide/development/navigation-menus.html#interface

export const menu: CoreMenu[] = [
	// Dashboard
	{
		id: 'dashboard',
		title: 'Dashboard',
		type: 'item',
		// role: ['admin','admin_user'], //? To hide collapsible based on user role
		icon: 'home',
		url: '/dashboard',
	},
	{
		id: 'settings',
		title: 'Settings',
		type: 'item',
		// role: ['admin'], //? To hide collapsible based on user role
		icon: 'settings',
		url: '/settings',
	},
];
